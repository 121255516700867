<template>
  <div
      id="sidenav-collapse-main"
      class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse
            collapse-ref="dashboardsExamples"
            nav-text="Dashboard"
            :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <Shop/>
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item
                  :to="{ name: 'Default' }"
                  mini-icon="M"
                  text="Dashboard"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="mt-3 nav-item">
        <h6
            class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
            :class="isRTL ? 'me-4' : 'ms-2'"
        >
          Seiten
        </h6>
      </li>
      <li class="nav-item">
        <sidenav-collapse
            collapse-ref="apiExamples"
            nav-text="Einstellungen"
            :class="getRoute() === 'examples' ? 'active' : ''"
        >
          <template #icon>
            <Settings/>
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <sidenav-item
                  :to="{ name: 'Profile' }"
                  mini-icon="P"
                  text="Profil Einstellungen"
              />
              <sidenav-item
                  v-if="requireAdminOrGroupLeader"
                  :to="{ name: 'Users' }"
                  mini-icon="U"
                  text="Benutzer Management"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>


      <!-- Aktuelles -->
      <li class="nav-item">
        <sidenav-collapse
            collapse-ref="aktuelles"
            nav-text="Aktuelles"
            :class="getRoute() === 'aktuelles' ? 'active' : ''"
        >
          <template #icon>
            <Document/>
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <sidenav-item
                  :to="{ name: 'AbrechnungList' }"
                  mini-icon="A"
                  text="Aktuelle Abrechnung"
              />
              <sidenav-item
                  :to="{ name: 'AktuellesList' }"
                  mini-icon="I"
                  text="Informationen"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <!-- Beratung -->
      <li class="nav-item">
        <sidenav-collapse
            collapse-ref="applicationsExamples"
            nav-text="Beratung"
            :class="getRoute() === 'beratung' ? 'active' : ''"
        >
          <template #icon>
            <Settings/>
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->

              <sidenav-collapse-item
                  refer="profileExample"
                  mini-icon="R"
                  text="Renten-Clear Up"
              >
                <template #nav-child-item>
                  <sidenav-item
                      :to="{ name: 'ClearUpToolList' }"
                      mini-icon="CU"
                      text="ClearUp Tool"
                  />

                  <sidenav-item
                      :to="{ name: 'RentenClearUpErklaerVideoList' }"
                      mini-icon="E"
                      text="Erklärvideo"
                  />

                  <sidenav-item
                      :to="{ name: 'ClearUpList' }"
                      mini-icon="U&I"
                      text="Unterlagen & Infos"
                  />
                </template>
              </sidenav-collapse-item>
              <!-- nav links -->
              <sidenav-item
                  :to="{ name: 'PflegeList' }"
                  mini-icon="P"
                  text="Pflege-Thema"
              />
              <!-- nav links -->
              <sidenav-item
                  :to="{ name: 'WohngeldKZuschlagList' }"
                  mini-icon="WK"
                  text="Wohngeld/Kinderzuschlag"
              />
              <!-- nav links -->
              <sidenav-item
                  :to="{ name: 'SonstigesList' }"
                  mini-icon="S"
                  text="Sonstiges"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <!-- Meetings -->
      <li class="nav-item">
        <sidenav-collapse
            collapse-ref="meetings"
            nav-text="Meetings & Fachwissen"
            :class="getRoute() === 'meetings' ? 'active' : ''"
        >
          <template #icon>
            <Office/>
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item
                  :to="{ name: 'Meetings' }"
                  mini-icon="M"
                  text="Videos"
              />

            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <!-- Anwerbung -->
      <li class="nav-item">
        <sidenav-collapse
            collapse-ref="anwerbung"
            nav-text="Anwerbung"
            :class="getRoute() === 'anwerbung' ? 'active' : ''"
        >
          <template #icon>
            <Basket/>
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item
                  :to="{ name: 'AnwerbungList' }"
                  mini-icon="NT"
                  text="neuer Talente"
              />

            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <!-- Ausbildung -->
      <li class="nav-item">
        <sidenav-collapse
            collapse-ref="ausbildung"
            nav-text="Ausbildung"
            :class="getRoute() === 'ausbildung' ? 'active' : ''"
        >
          <template #icon>
            <Spaceship/>
          </template>

          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item
                  :to="{ name: 'EinarbeitungList' }"
                  mini-icon="E"
                  text="Einarbeitung"
              />
              <sidenav-item
                  :to="{ name: 'UnternehmenspraesentationList' }"
                  mini-icon="U"
                  text="Unternehmenspräsentation"
              />
              <sidenav-item
                  :to="{ name: 'EmpfehlungList' }"
                  mini-icon="E"
                  text="Empfehlungsmanagement"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="mt-3 nav-item">
        <hr class="mt-0 horizontal dark"/>
        <h6
            class="text-xs ps-4 ms-2 text-uppercase font-weight-bolder opacity-6"
            :class="isRTL ? 'me-4' : 'ms-2'"
        >

        </h6>
      </li>
    </ul>
  </div>
  <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <sidenav-card
        :class="cardBg"
        text-primary="Hilfe benötigt?"
        text-secondary="Schreibe mir bitte eine Mail."
        :route="'mailto:kelvin.bill@dvag.de'"
        label="MAIL"
        icon="ni ni-diamond"
    />
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavCard from "./SidenavCard.vue";
import SidenavCollapseItem from "./SidenavCollapseItem.vue";
import Settings from "../../components/Icon/Settings.vue";
import Basket from "../../components/Icon/Basket.vue";
import Shop from "../../components/Icon/Shop.vue";
import Spaceship from "../../components/Icon/Spaceship.vue";
import {mapState} from "vuex";
import Office from "@/components/Icon/Office.vue";
import Document from "@/components/Icon/Document.vue";

export default {
  name: "SidenavList",
  components: {
    Document,
    Office,
    SidenavItem,
    SidenavCollapse,
    SidenavCard,
    SidenavCollapseItem,
    Settings,
    Basket,
    Shop,
    Spaceship,
  },
  props: {
    cardBg: {
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapState(["isRTL"]),
    profile() {
      return this.$store.getters["profile/profile"];
    },
    requireAdmin() {
      if (this.$store.getters["auth/loggedIn"] && this.profile) {
        if (this.profile.scope == 2) return true;
      }
      return false;
    },
    requireAdminOrGroupLeader() {
      if (this.$store.getters["auth/loggedIn"] && this.profile) {
        switch (this.profile.scope) {
          case 2:
            return true;
          case 57:
            return true;
          default:
            return false;
        }
      }
      return false;
    },
    requireCreator() {
      if (this.$store.getters["auth/loggedIn"] && this.profile) {
        if (this.profile.scope == 2) return true;
      }
      return false;
    },
  },
  async created() {
    if (this.$store.getters["auth/loggedIn"]) {
      try {
        await this.$store.dispatch("profile/getProfile");
        this.profileChange = {...this.profile};
      } catch (error) {
        try {
          await this.$store.dispatch("auth/logout");
        } finally {
          this.$router.push("/login");
        }
      }
    }
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>
